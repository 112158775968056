import React from "react";
import Seo from "../../../components/seo";
import Industry from "../../../layouts/Industries/Industry";
import {graphql, useStaticQuery} from 'gatsby';
import Layout from "../../../components/layout";
import {Retail} from '../../../components/svgs/industries';

const RetailsIndustryPageEn = () => {
  const data = useStaticQuery(graphql`
    query RetailsIndustryDatasEn {
      allContentfulSecteurs(filter: {contentful_id: {eq: "7olBztWibd2z1GSgYNQwOl"}}) {
        edges {
          node {
            name
            permalink
            contentful_id
            advantages {
              title
              description
              icon
              visual {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
            installations {
              gatsbyImageData(layout: CONSTRAINED)
            }
            clients {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  return (
    <Layout 
    slider={false} 
    switchLink={data.allContentfulSecteurs.edges[0].node.permalink} 
    lang="en-CA"
    title={data.allContentfulSecteurs.edges[1].node.name} 
    subTitle="Digital signage in"
    icon={<Retail/>}>
      <Seo title="Retail Business and Commercial Signage Display"
           description="Commercial digital signage captures the attention of 78% of people exposed, this is a great way to get your message across! Improve your sales, communicate effectively and give up on poster management with Attractif."
           lang="en-CA"/>
      <Industry lang="en-CA" datas={data.allContentfulSecteurs.edges[1].node}/>
    </Layout>
  )
}

export default RetailsIndustryPageEn
